.collapsible-item {
    font-family:
        Roboto Condensed,
        serif;
    font-weight: 400;
    font-size: 13px;
    width: 100%;
    position: relative;
    margin-bottom: 5px;
}

.collapsible-item svg {
    margin-left: -16px;
}

.collapsible-item:after {
    content: '';
    background: linear-gradient(to right, transparent, #dc143c, transparent);
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: -10px;
}
